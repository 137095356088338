<ng-container *ngIf="infinite$ | async">
  <div
    class="doc-list"
    nbInfiniteList
    listenWindowScroll
    [threshold]="1000"
    (bottomThreshold)="advanceScrollInf()"
  >
    <div
      [ngStyle]="{
        width: isHorizontal ? recordSize + 'px' : 'auto',
        height: !isHorizontal ? recordSize + 'px' : 'auto'
      }"
      *ngFor="let d of docs; trackBy: trackByIdx"
    >
      <ng-container *ngTemplateOutlet="child; context: { doc: d }"></ng-container>
    </div>
    <ng-container *ngIf="isEnd && !isHorizontal">
      <div class="row end-results">
        <div class="text-center col-md-8 col-md-offset-2">
          <p>No more results. We're always adding new items!</p>
          <h3>Russell's Tree & Shrub Farm</h3>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
