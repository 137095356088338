import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { Account } from 'shared';
import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  IdTokenResult,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  user,
  User,
  UserCredential
} from '@angular/fire/auth';
import { DbService } from './db.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _auth = inject(Auth);
  private _db = inject(DbService);

  $user: Observable<User | Account> = new BehaviorSubject(undefined);

  logout$ = new Subject<boolean>();
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.$user = authState(this._auth);
    }
  }

  async createUserWithEmailAndPassword(account: Account, password: string): Promise<void> {
    const cred = await createUserWithEmailAndPassword(this._auth, account.email, password);
    await sendEmailVerification(cred.user);
    account.displayName =
      account.displayName.charAt(0).toUpperCase() + account.displayName.substring(1);
    await updateProfile(cred.user, { displayName: account.displayName });
    const uid = cred.user.uid;

    await this._db.setDoc('wholesale-registrations', uid, {
      ...account,
      id: uid,
      createdBy: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
      updatedBy: uid
    });

    await this.router.navigateByUrl('/wholesale/auth');
    return this.logout();
  }

  async createAnonymousUser() {
    const anonUser = await signInAnonymously(this._auth);
    console.log(anonUser);
  }

  async sendEmailVerification(): Promise<void> {
    const u = await this.getCurrentUser();
    return sendEmailVerification(u);
  }

  loginWithEmail(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this._auth, email, password);
  }

  logout(): Promise<void> {
    this.logout$.next(true);
    return signOut(this._auth);
  }

  requestEmailPasswordLink(email: string): Promise<void> {
    return sendPasswordResetEmail(this._auth, email);
  }

  async refreshUser(): Promise<void> {
    const u = await this.getCurrentUser();
    if (u) {
      await u.getIdToken(true);
      await u.reload();
    }
  }

  async getIdTokenResult(forceRefresh = false): Promise<IdTokenResult> {
    const currentUser = await this.getCurrentUser();
    return currentUser ? currentUser.getIdTokenResult(forceRefresh) : null;
  }

  getCurrentUser(): Promise<User> {
    return authState(this._auth).pipe(take(1)).toPromise();
  }
}
