import { take } from 'rxjs/operators';
import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationStart, RouterEvent, Router, NavigationEnd, Event } from '@angular/router';
import { AuthService } from './auth.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import {
  Analytics,
  logEvent as firebaseLogEvent,
  setAnalyticsCollectionEnabled,
  setUserId
} from '@angular/fire/analytics';
import { DbService } from './db.service';

declare let newrelic;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private _db = inject(DbService);
  private _analytics = inject(Analytics);

  private _navStartUrl = '';
  private _navEndUrl = '';
  isBrowser = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.router.events.subscribe(
      (e: Event | RouterEvent) => {
        if (this.isBrowser) {
          if (e instanceof NavigationStart) {
            this._navStartUrl = e.url;
            this.logEvent('screen_view', {
              firebase_screen: e.url
            });
            this.logEvent('page_view', { page: e.url }, false);
            this.newRelicInteraction(e.url);
          }
          if (e instanceof NavigationEnd && this._navStartUrl !== e.url) {
            this._navEndUrl = e.url;
            this.logEvent('screen_view', {
              firebase_screen: e.url
            });
            this.logEvent('page_view', { page: e.url }, false);
            this.newRelicInteraction(e.url);
          }
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  async enable(): Promise<void> {
    if (this.isBrowser && this._analytics.app) {
      this.setUser('VISITOR');
      setAnalyticsCollectionEnabled(this._analytics, true);
    }
    return;
  }

  setUser(uid: string) {
    if (this.isBrowser && uid && this._analytics.app) {
      setUserId(this._analytics, uid);
    }
  }

  getNavUrls() {
    return { start: this._navStartUrl, end: this._navEndUrl };
  }

  newRelicInteraction(name: string) {
    if (this.isBrowser && newrelic) {
      newrelic.setPageViewName(name, environment.production ? 'russellsny.com' : 'development');
      newrelic.setCurrentRouteName(name);
      let i = newrelic.interaction();
      i.setName(name);
      i.save();
    }
  }

  async logEvent(eventName: string, data: any = {}, persistToDb = true): Promise<void> {
    if (this.isBrowser && this._analytics.app) {
      firebaseLogEvent(this._analytics, eventName, data);
    }
    const user = (await this.authService.$user.pipe(take(1)).toPromise()) || ({} as any);
    if (persistToDb) {
      const id = this._db.createId('user-analytic-events');
      const event: any = {
        collection: 'user-analytic-events',
        eventName,
        data,
        auth: user.uid || 'retail',
        name: user.displayName || 'retail',
        isWholesaleSegment: user.uid ? true : false,
        id: id.id,
        createdAt: new Date()
      } as any;
      await this._db.setDoc('user-analytic-events', id.id, event);
    }
    return;
  }
}
