import { NbAuthModule } from '@nebular/auth';
import { ShellModule } from './shared/shell/shell.module';
import { environment } from './../environments/environment';
import { SharedModule } from './shared/shared.module';
import { NgModule, Injectable, Injector, ErrorHandler, APP_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbSidebarModule,
  NbToastrService,
  NbMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbToastrModule,
  NbWindowModule
} from '@nebular/theme';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FirebaseAuthInterceptor } from './interceptors/firebase-auth.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotFoundGuard } from './shared/404/not-found.guard';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { initializeAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { providePerformance, getPerformance } from '@angular/fire/performance';

declare let newrelic;
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error: Error): void {
    if (error?.name !== 'FirebaseError' && error?.message?.indexOf('FirebaseError') < 0) {
      const toastService = this.injector.get(NbToastrService);
      const message = error.message;
      toastService.danger(message, 'We hit a glitch', {
        duration: 10000,
        destroyByClick: true
      });
    }
    console.error('Global Error Handler', error);
    newrelic.noticeError(error, { globalHandler: true });
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbAuthModule.forRoot(),
    NgxSpinnerModule,
    ShellModule,
    SharedModule
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideRemoteConfig(() => {
      const r = getRemoteConfig();
      r.settings.minimumFetchIntervalMillis = environment.production ? 300000 : 5000;
      return r;
    }),
    provideStorage(() => getStorage()),
    provideAnalytics(() => {
      const a = getApp();
      const anal = initializeAnalytics(a, {
        config: {
          send_page_view: true,
          allow_ad_personalization_signals: true,
          anonymize_ip: true
        }
      });
      anal.app.automaticDataCollectionEnabled = true;
      return anal;
    }),
    providePerformance(() => {
      const p = getPerformance();
      p.dataCollectionEnabled = true;
      p.instrumentationEnabled = true;
      return p;
    }),
    {
      provide: APP_ID,
      useValue: 'serverApp'
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    { provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthInterceptor, multi: true },
    NotFoundGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
