import { isPlatformBrowser } from '@angular/common';
import { mergeMap, map, scan, tap, startWith, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
  PLATFORM_ID,
  Inject
} from '@angular/core';

import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import Fuse from 'fuse.js';
import { Doc } from 'shared';
import { NbLayoutScrollService } from '@nebular/theme';

export interface ScrollerQuery {
  service: any;
  serviceFunction: string;
  cursorKey: string;
}

@Component({
  selector: 'app-doc-scroller',
  templateUrl: './doc-scroller.component.html',
  styleUrls: ['./doc-scroller.component.scss']
})
export class DocScrollerComponent implements OnInit {
  @ContentChild(TemplateRef, { static: true })
  child;

  // state and data
  docs: Doc[] = [];
  docCount = 0;
  isEnd = false;
  previousEndIndex = 0;

  // is browser
  isBrowser = true;

  // searching status
  private searchingStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private pendingSearches = 0;

  infinite$: Observable<any[]>;
  reset$: Subject<boolean> = new Subject();
  private trigger = new BehaviorSubject(undefined);
  private toDelete: Doc;
  private cursors = new Map<number, Doc>();
  private queryObservables: Observable<any>[] = [];

  // output for when to send new paged query
  @Output() updateQueries = new EventEmitter<Map<number, Doc>>();
  // inputs
  @Input() keywordScore = ''; // whether or not to score values on keyword match
  @Input() recordSize = 75;
  @Input() isHorizontal = false;
  @Input() parentContent;
  @Input() useWindow = false;
  @Input() orderByFn: (a: any, b: any) => number; // used if multiple sources are in query obs (i.e. comblatest)
  @Input() filterFn: (a: any, b: any) => boolean;
  @Input() reduceFn: (
    previousValue: any,
    currentValue: any,
    currentIndex: number,
    array: any[]
  ) => any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private scrollService: NbLayoutScrollService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser && !this.parentContent) {
      this.parentContent = document.getElementsByTagName('nb-layout-column').item(0);
    }
  }

  private setObservable(): void {
    this.infinite$ = this.trigger.pipe(
      tap(() => {
        this.searchingStatus.next(true);
      }),
      mergeMap((c) => this.getBatch()),
      scan((acc, batch) => {
        this.isEnd = batch.length < 1;
        if (this.toDelete) {
          if (acc.hasOwnProperty(this.toDelete.id)) {
            delete acc[this.toDelete.id];
          }
          this.toDelete = null;
        }
        batch = batch.reduce((accu, cur) => {
          const data = [...cur];
          let id = cur.id || '';
          if (!id) {
            cur.forEach((c) => {
              id += `${c.id}-`;
            });
          }
          return { ...accu, [id]: data };
        }, {});
        return { ...acc, ...batch };
      }, []),
      map((v) => {
        return Object.values(v);
      }),
      tap((records) => {
        this.pendingSearches--;
        if (this.pendingSearches < 1) {
          this.searchingStatus.next(false);
        }
        this.docs = records;
        this.docCount = records.length;
      }),
      takeUntil(this.reset$)
    );
  }

  private getBatch(): Observable<any[]> {
    let queryObs = [];
    queryObs = this.queryObservables.map((obs) => {
      return obs.pipe(startWith([]));
    });
    this.pendingSearches = queryObs.length;
    return combineLatest(queryObs).pipe(
      map((results: any[][]) => {
        let values = [];
        results.forEach((res, order) => {
          // update cursor
          if (res.length > 0) {
            this.cursors.set(order, res[res.length - 1]);
          }
          values = [...values, ...res];
        });
        if (this.reduceFn) {
          values = values.reduce(this.reduceFn, []);
        }
        if (this.filterFn) {
          values = values.filter(this.filterFn);
        }
        if (this.orderByFn) {
          values = values.sort(this.orderByFn);
        }
        if (this.keywordScore) {
          const keywordSearchScores = new Map<string, number>();
          // build fuse search with results
          const fuse = new Fuse(
            values.map((v) => {
              return v.data();
            }),
            {
              includeScore: true,
              findAllMatches: true,
              keys: ['name']
            }
          );
          // score top results
          fuse.search(this.keywordScore).forEach((result) => {
            keywordSearchScores.set(result.item.id, result.score);
          });
          // sort based on results. lower better
          values = values.sort((a, b) => {
            const aScore = keywordSearchScores.get(a.data().id) || 1;
            const bScore = keywordSearchScores.get(b.data().id) || 1;
            if (aScore === bScore) {
              return a.data().name > b.data().name ? 1 : -1;
            }
            return aScore > bScore ? 1 : -1;
          });
        }
        return values;
      })
    );
  }

  setQueryObservables(queryObservables: Observable<any>[]): void {
    while (this.queryObservables.length > 0) {
      // clear for next query set
      this.queryObservables.pop();
    }
    this.queryObservables.push(...queryObservables);
  }

  getSearchStatus(): Observable<boolean> {
    return this.searchingStatus.asObservable();
  }

  fetch(): void {
    if (!this.infinite$) {
      this.setObservable();
    }
    this.trigger.next(true);
  }

  advanceScrollInf() {
    if (this.isEnd) {
      return;
    }
    this.updateQueries.next(this.cursors);
  }

  advanceScroller(e): void {
    if (this.isEnd) {
      return;
    }

    const previousEndIndex = this.previousEndIndex;
    this.previousEndIndex = e.scrollEndPosition;

    // infinite at bottom
    if (e.endIndexWithBuffer === this.docs.length - 1 && previousEndIndex !== e.scrollEndPosition) {
      this.updateQueries.next(this.cursors);
    }
  }

  deleteDoc(doc: Doc): void {
    if (doc && doc.id) {
      this.toDelete = doc;
    }
  }

  pause(): void {
    this.isEnd = true;
  }

  resume(): void {
    this.isEnd = false;
  }

  async reset(): Promise<void> {
    this.reset$.next(true);
    this.searchingStatus.next(false);
    this.previousEndIndex = 0;
    this.pendingSearches = 0;
    this.docs = [];
    this.docCount = 0;
    this.isEnd = false;
    this.cursors.clear();
    this.infinite$ = null;
  }

  scrollToTop(offset: number = 0): void {
    if (this.scrollService && this.isBrowser) {
      this.scrollService.scrollTo(0, 0);
    }
  }

  trackByIdx(i, doc): void {
    return doc.id;
  }
}
