import { map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { GalleryRecord } from 'shared';
import { Observable } from 'rxjs';
import { FeaturedItem } from 'shared';
import {
  FieldPath,
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
  where,
  WhereFilterOp
} from '@angular/fire/firestore';
import { DbService } from './../../services/db.service';
export interface GalleryCategoryFilter {
  fieldPath: string | FieldPath;
  opStr: WhereFilterOp;
  value: any;
}

@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private _db = inject(DbService);

  galleryRecordMetadata = null;

  constructor() {}

  async fetchMetadata(): Promise<void> {
    if (!this.galleryRecordMetadata) {
      const r = await this._db.readDoc('collection-metadata', 'gallery-records-2023');
      this.galleryRecordMetadata = r;
    }
  }

  keywordSearch(keyword: string, limitVal = 3): Observable<GalleryRecord[]> {
    const constraints: QueryConstraint[] = [
      where('hasPhotos', '==', true),
      where('searchTerms', 'array-contains', keyword.toLowerCase()),
      limit(limitVal)
    ];
    return this._db.queryCollection$<GalleryRecord>('gallery-records', constraints).pipe(
      map((records) => {
        return records.map((record) => {
          return record as GalleryRecord;
        });
      })
    );
  }

  getKeywordSearchObservable(
    keyword: string,
    rowItems = 3,
    cursors?: Map<number, any[]>
  ): Observable<GalleryRecord[][]> {
    const constraints: QueryConstraint[] = [
      where('hasPhotos', '==', true),
      where('searchTerms', 'array-contains', keyword.toLowerCase().trim()),
      orderBy('name', 'asc')
    ];

    if (cursors && cursors.has(0)) {
      const cursor = cursors.get(0).pop().name;
      constraints.push(startAfter(cursor));
    }

    constraints.push(limit(15));

    return this._db.queryCollection$<GalleryRecord>('gallery-records', constraints).pipe(
      map((rec) => {
        let row = [];
        const records = [];
        rec.forEach((r, index) => {
          if (index !== 0 && index % rowItems === 0) {
            records.push([...row]);
            row = [];
          }
          row.push(r);
        });
        if (row.length > 0) {
          records.push([...row]);
        }
        return records;
      })
    );
  }

  getCategoryScrollerObservable(
    parentCollection: string,
    category: string,
    rowItems = 3,
    filters: GalleryCategoryFilter[] = [],
    cursors?: Map<number, any[]>
  ): Observable<GalleryRecord[][]> {
    const constraints: QueryConstraint[] = [
      where('hasPhotos', '==', true),
      where('galleryCollection', '==', parentCollection),
      where('category', '==', category)
    ];

    filters.forEach((f) => {
      constraints.push(where(f.fieldPath, f.opStr, f.value));
    });

    constraints.push(orderBy('name', 'asc'));

    if (cursors && cursors.has(0)) {
      const cursor = cursors.get(0);
      if (cursor.length > 0) {
        constraints.push(startAfter(cursor.pop().name));
      }
    }

    constraints.push(limit(20));

    return this._db.queryCollection$<GalleryRecord>('gallery-records', constraints).pipe(
      map((rec) => {
        let row = [];
        const records: GalleryRecord[][] = [];
        rec.forEach((r, index) => {
          if (index !== 0 && index % rowItems === 0) {
            records.push([...row]);
            row = [];
          }
          row.push(r);
        });
        if (row.length > 0) {
          records.push([...row]);
        }
        return records;
      })
    );
  }

  getGalleryRecordById(id): Promise<GalleryRecord> {
    return this._db.readDoc('gallery-records', id);
  }

  getFeaturedItem$(): Observable<FeaturedItem> {
    return this._db.queryDoc$<FeaturedItem>('featured-items', 'MAIN');
  }

  getRecentlyFeaturedItems$(limitVal = 8): Observable<FeaturedItem[]> {
    return this._db.queryCollection$<FeaturedItem>('featured-items', [
      orderBy('createdAt', 'desc'),
      limit(limitVal)
    ]);
  }
}
