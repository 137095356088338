import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  CollectionReference,
  doc,
  docSnapshots,
  DocumentReference,
  Firestore,
  getDoc,
  query,
  QueryConstraint,
  setDoc,
  updateDoc
} from '@angular/fire/firestore';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  private _db = inject(Firestore);

  constructor() {}

  createId<T>(collectionName: string): { id: string; ref: DocumentReference<T> } {
    const newDocRef = doc(collection(this._db, collectionName));
    return { id: newDocRef.id, ref: newDocRef } as { id: string; ref: DocumentReference<T> };
  }

  readDoc<T>(collectionName: string, docId: string): Promise<T> {
    const ref = doc(this._db, collectionName, docId) as DocumentReference<T>;
    return getDoc(ref).then((snap) => {
      return snap.data() as T;
    });
  }

  queryDoc$<T>(collectionName: string, docId: string): Observable<T> {
    const ref = doc(this._db, collectionName, docId) as DocumentReference<T>;
    return docSnapshots<T>(ref).pipe(
      map((s) => {
        return s.data();
      })
    );
  }

  queryCollection$<T>(
    collectionName: string,
    constraints: QueryConstraint[] = []
  ): Observable<T[]> {
    const col = collection(this._db, collectionName) as CollectionReference<T>;
    const q = query(col, ...constraints);
    return collectionData<T>(q);
  }

  async setDoc(collectionName: string, docId: string, data: any): Promise<void> {
    await setDoc(doc(this._db, collectionName, docId), data);
    return;
  }

  updateDoc(collectionName: string, docId: string, data: any) {
    return updateDoc(doc(this._db, collectionName, docId), data);
  }
}
